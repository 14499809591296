import OSS from 'ali-oss'
import { Message } from 'element-ui'

let client = new OSS({
    // // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
    region: 'oss-cn-shanghai',
    // // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
    accessKeyId: 'LTAI5tQbjWwM319cSVWn5vjz',
    accessKeySecret: 'hxnNO58ifAyn0myOlW1IZT3XVi1qDx',
    endpoint: 'oss-cn-shanghai.aliyuncs.com',
    bucket: 'easy-shoot',
});
const fileTypes = ['jpg', 'jpeg', 'png']
export async function uploadFile(folder, data) {
    try {
        let fileName = null
        const fileType = data.type.split('/')[1]
        if (!fileTypes.includes(fileType)) {
            Message.error('文件类型不支持')
            return false
        }
        if (data.size / 1048576 > 5) {
            Message.error('文件大小不能超过5M')
            return false
        }
        fileName = folder + new Date().getTime() + '.' + fileType
        const { name } = await client.put(fileName, data)
        console.log(name)
        return `https://easy-shoot.oss-cn-shanghai.aliyuncs.com/${name}`
    } catch (e) {
        console.error(e)
    }

}

