<template>
  <div style="display: inline-block; width: 100%;">
    <el-upload class="file-uploader"
               action=""
               :multiple="multiple"
               :limit="20"
               :http-request="tryUploadFile"
               :before-upload="beforeUpload"
               :on-remove="handleRemove"
               :on-exceed="handleExceed"
               :show-file-list="showFileList">
      <el-button :type="textType" :style="{ textAlign: textType === 'text' ? 'left' : 'center'}">{{btnText}}</el-button>
    </el-upload>
    <span class="textStyle" v-show="!showFileList">{{fileName ? fileName : `图片最大支持${uploadFileSizeLimit}M`}}</span>
  </div>
</template>

<script>
  import { uploadFile } from '@/mixins/uploadImg'
  export default {
    name: 'FileUploadBtn',
    props: {
      multiple: {
        type: Boolean,
        default: false
      },
      btnText: {
        type: String,
        default: '选择文件'
      },
      showFileList: {
        type: Boolean,
        default: false
      },
      textType: {
        type: String,
        default: 'primary'
      },
      defaultValue: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        fileName: '',
        fileList: [],
        uploadFileSizeLimit: 5
      }
    },
    watch: {
      defaultValue(n) {
        this.fileName = n
      }
    },
    methods: {
      async tryUploadFile(fileInfo) {
        console.log(fileInfo)
        const url = await uploadFile('policy', fileInfo.file)
        this.fileName = fileInfo.file.name
        this.$emit('uploadSuccess', url)
      },
      handleRemove(file, fileList) {
        this.fileList = fileList
        this.$emit('uploadSuccess', fileList)
      },
      handleExceed() {
        this.$message.warning('上传数量超出限制')
      },
      beforeUpload(fileInfo) {
        const result = fileInfo.size < this.uploadFileSizeLimit * 1024 * 1024
        if (!result) {
          this.$message.warning(`文件不能超过${this.uploadFileSizeLimit}M`)
        }
        return result
      },
    }
  }
</script>

<style lang="scss">
.file-uploader{
  display: inline-block;
  .el-upload{
    padding: 0;
  }
}
  .textStyle{
    display: inline-block;
    width: calc(100% - 90px);
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    margin-left: 8px;
  }
</style>
